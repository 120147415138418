<template>
    <div>
        <div class="ft20 cl-main ftw600">文章列表</div>

        <div class="mt20">
            <div class="bg-w">
                <div class="pd30">
                    <div>
                        <a-button type="primary" icon="plus" @click="addTypeAct()">添加文章</a-button>
                    </div>
                    <div class="form-search-box ">
                        <a-form layout="inline">
                            <a-form-item label="文章名称">
                                <a-input v-model="search.name" placeholder="请输入文章名称"></a-input>
                            </a-form-item>
                            <a-form-item v-if="shop_id==0" label="选择站点">
                                <div>
                                    <a-select v-model="search.search_shop_id" style="width: 200px" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择" >
                                        <a-select-option value="">全部站点</a-select-option>
                                        <a-select-option :value="item.shop_id" :key="index" v-for="(item,index) in shop_list">{{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </a-form-item>
                            <a-form-item label="文章分类">
                                <div>
                                    <a-tree-select v-model="search.article_category_id" style="width: 200px"
                                                   :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="category_lists"
                                                   placeholder="请选择" >
                                    </a-tree-select>
                                </div>
                            </a-form-item>
                            <a-form-item label="文章类型">
                                <div>
                                    <a-select style="width: 120px" placeholder="全部类型" v-model="search.type">
                                        <a-select-option value="">全部类型</a-select-option>
                                        <a-select-option :value="item.key" :key="index" v-for="(item,index) in type_list">{{ item.title }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </a-form-item>
                            <a-form-item>
                                <a-button @click="searchAct" type="primary">查询</a-button>
                                <a-button @click="cancelAct" class="ml10">取消</a-button>
                            </a-form-item>


                        </a-form>
                    </div>

                    <div class="">
                        <div class="mt30">
                            <a-radio-group v-model="search.audit_status" button-style="solid" @change="changeStatus">
                                <a-radio-button :value="1">
                                    已审核
                                </a-radio-button>
                                <a-radio-button :value="0">
                                    待审核
                                </a-radio-button>
                                <a-radio-button :value="-1">
                                    已退回
                                </a-radio-button>
                            </a-radio-group>
                        </div>
                        <div class="wxb-table-gray">
                            <a-table rowKey="id" :columns="columns"  :pagination="pagination" @change="handleTableChange"
                                     :data-source="datas" :loading="loading">

                                <div slot="icon" slot-scope="icon,record" class="project-type-icon">
                                    <img v-if="icon != null" :src="icon"/>
                                </div>
                                <div slot="category" slot-scope="category,record" class="project-type-icon">
                                    {{category.name}}
                                </div>
                                <div slot="shop" slot-scope="shop,record" class="project-type-icon">
                                    <a-tag v-if="shop" color="purple">
                                        {{ shop.name}}
                                    </a-tag>
                                    <a-tag v-else color="green">平台文章</a-tag>
                                </div>
                                <div slot="status" slot-scope="status,record">
                                    <a-switch :loading="switchloading"  v-model="record.statusswitch" checked-children="显示"  un-checked-children="隐藏" @change="onClickswitch(record)"  />
                                </div>

                                <template slot="action" slot-scope="record,index">
                                    <div class="flex center">
                                        <a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
                                            <a-menu slot="overlay">
                                                <a-menu-item>
                                                    <a class="menu-act" href="javascript:;" @click="editTypeAct(record)">
                                                        <i class="iconfont ft14 iconedit"></i>
                                                        <span class="ml10">编辑</span>
                                                    </a>
                                                </a-menu-item>
                                                <a-menu-item>
                                                    <a class="menu-act" v-if="record.type!=1" href="javascript:;" @click="delTypeAct(record)">
                                                        <i class="iconfont ft14 icondelete"></i>
                                                        <span class="ml10">删除</span>
                                                    </a>
                                                </a-menu-item>
                                            </a-menu>
                                        </a-dropdown>
                                    </div>
                                </template>
                            </a-table>
                        </div>
                    </div>

                    <div v-if="editTypeVisible">
                        <edit-type :visible="editTypeVisible" :article_id="article_id" @cancel="cancelEditType" @ok="okEditType"></edit-type>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {listMixin} from '@/common/mixin/list';
    import editType from './components/modal/editArticle.vue';
    export default{
        mixins: [listMixin],
        components:{
            editType,
        },
        data(){
            return{
                loading: false,
                editTypeVisible:false,
                switchloading:false,
                pagination: {
                    current: 1,
                    pageSize: 10, //每页中显示10条数据
                    total: 0,
                    showTotal:false,
                },
                datalits:[],
                shop_id:0,
                shop_list:[],
                type_list:[],
                category_lists:[],
                article_id:0,
                search:{
                    name:'',
                    article_category_id:'',
                    type:'',
                    search_shop_id:"",
                    audit_status:1,
                },
                columns: [
                    // {title: '文章编号',dataIndex: 'id',align: 'center'},
                    // {title: '文章标题',dataIndex: 'name',align: 'center',ellipsis: true},
                    // {title: '文章主图',dataIndex: 'thumb',align: 'center',scopedSlots: {customRender: 'icon'}},
                    // {title: '文章分类',dataIndex: 'category',align: 'center',scopedSlots:{customRender: 'category'}},
                    // {title: '文章类型',dataIndex: 'type_text',align: 'center',ellipsis: true},
                    // {title: '排序',dataIndex: 'sort',align: 'center',ellipsis: true},
                    // {title: '创建时间',dataIndex: 'create_time',align: 'center',ellipsis: true},
                    // {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
                ],
                datas: [],
            }
        },
        created() {
            this.loaddata();
        },
        methods:{
            loaddata(){
                this.$http.api('admin/getAppointmenconfig',{}).then(res=>{
                    if(res.config.shop_id){
                        this.shop_id=res.config.shop_id;
                    }
                    this.shop_list=res.shop_list;
                });
            },
            changeStatus(){
                this.pagination.current = 1;
                this.getLists();
            },
            onClickswitch(row){
                this.switchloading=true;
                const status=row.status ===0 ? 1 :0;
                try{
                    this.$http.api('platform/article/activityStatus',{
                        id:row.id,
                        status:status
                    }).then(res=>{
                        row.status=status;
                    }).catch(res=>{
                        this.switchloading=false;
                    });

                }catch (err) {
                    this.switchloading=false;
                    console.error(err.message);
                }finally {
                    this.switchloading=false;
                }

            },
            getLists(){
                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('platform/article/lists',{
                    limit:this.pagination.pageSize,
                    page:this.pagination.current,
                    name:this.search.name,
                    category_id:this.search.article_category_id,
                    type:this.search.type,
                    search_shop_id:this.search.search_shop_id,
                    audit_status:this.search.audit_status,
                }).then(res=>{
                    this.pagination.total=res.total;
                    this.datas=res.list;
                    this.type_list=res.type_list;
                    this.category_lists=res.category_lists;
                    this.pagination.showTotal=(total)=>"共"+res.total+"条";
                    if(this.columns.length==0){
                        this.columns=res.columns;
                    }
                    this.loading=false;
                }).catch(res=>{
                    this.loading=false;
                })
            },

            delTypeAct(record){
                this.$confirm({
                    title:'确认删除这篇文章吗？',
                    okText:"确定",
                    okType:"danger",
                    cancelText:"取消",
                    onOk : ()=>{
                        return new Promise((resolve,reject)=>{
                            this.$http.api('platform/article/delete',{
                                id:record.id,
                            }).then(res=>{
                                this.$message.success('删除成功',1,()=>{
                                    this.getLists();
                                });
                            }).catch(res=>{
                                console.log(res);
                            }).finally(()=>{
                                resolve();
                            })
                        })
                    }
                })
            },

            addTypeAct(){
                this.$router.push('/article/editArticle');
            },
            editTypeAct(record){

                this.$router.push('/article/editArticle?article_id='+record.id);
            },
            cancelEditType(){
                this.editTypeVisible=false;
            },
            okEditType(){
                this.editTypeVisible=false;
                this.getLists();
            },
            handleTableChange(pagination, filters, sorter) {
                this.pagination.current = pagination.current;
                this.getLists();
            },
        }
    }
</script>

<style>
    .projetc-index-cover-img{
        width: 80px;
        height: 60px;
    }
    .project-type-icon img{
        width: 60px;
        height: 60px;
    }
</style>
